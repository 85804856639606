import NextLink from 'next/link';
import React from 'react';
import { CmsModel } from 'api-types';
import { Button } from 'ui';
import styles from './NodeItem.module.css';

type NodeItemProps = {
    node: CmsModel.NavigationNode;
    onSelectNode?: (node: CmsModel.NavigationNode) => void;
};

export const NodeItem = ({ node, onSelectNode }: NodeItemProps) => {
    const { children, link } = node;
    const asButton = !!children?.length;

    const onClickHandler = () => onSelectNode?.(node);

    if (asButton) {
        return (
            <Button variant='link' iconName='chevron-right' iconPosition='right' className={styles.nodeButton} onClick={onClickHandler}>
                <div className={styles.content} children={link?.title} />
            </Button>
        );
    }

    return (
        <NextLink href={link?.url} passHref>
            <Button variant='link' target={link.target} className={styles.nodeLink} onClick={onClickHandler}>
                <div className={styles.content} children={link?.title} />
            </Button>
        </NextLink>
    );
};
