import { Text, AccordionItem } from 'ui';
import NextLink from 'next/link';
import React, { memo } from 'react';
import { weakKey } from '~/shared/utils/jsx';
import { MenuItemProps } from '..';
import {
    StyledDesktopMenu,
    StyledMenuGroup,
    StyledMenuItem,
    StyledMenuItems,
    StyledMobileMenu,
} from '../styled';

export const MenuItem = memo(({ menuItem }: MenuItemProps) => {
    const { link, children } = menuItem;
    const validChildren = children ? children.filter((node) => node.link.url) : [];

    const header = <Text variant="display6" as="h2" role="heading" children={link.title} />;

    return (
        <StyledMenuGroup>
            <StyledMobileMenu>
                <AccordionItem header={header}>
                    <StyledMenuItems>
                        {validChildren.map(({ link }) => (
                            <li key={weakKey(link)}>
                                <NextLink href={link.url} passHref prefetch={false}>
                                    <StyledMenuItem
                                        target={link.target}
                                        title={link.title}
                                        children={link.title}
                                    />
                                </NextLink>
                            </li>
                        ))}
                    </StyledMenuItems>
                </AccordionItem>
            </StyledMobileMenu>
            <StyledDesktopMenu>
                {header}
                <StyledMenuItems>
                    {validChildren.map(({ link }) => (
                        <li key={weakKey(link)}>
                            <NextLink href={link.url} passHref prefetch={false}>
                                <StyledMenuItem
                                    target={link.target}
                                    title={link.title}
                                    children={link.title}
                                />
                            </NextLink>
                        </li>
                    ))}
                </StyledMenuItems>
            </StyledDesktopMenu>
        </StyledMenuGroup>
    );
});
