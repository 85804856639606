import NextLink from 'next/link';
import React, { memo } from 'react';
import { CmsModel } from 'api-types';

import clsx from 'clsx';
import styles from './NavigationItem.module.css';
import { SvgIcon } from 'ui';

type Props = {
    link?: CmsModel.Link;
    subNodes?: CmsModel.NavigationNode[];
    active?: boolean;
    onClick?: () => void;
};

/**
 * Navigation item, which will render either as a link or button, based on it's childnodes presence.
 * has a active and inactive state.
 */
export const NavigationItem = memo((props: Props) => {
    const { link, subNodes, onClick } = props;

    if (subNodes?.length) {
        return (
            <button className={clsx(styles.navigationItem, {
                [styles.active]: props.active
            })}
                onClick={onClick}
                aria-expanded={props.active}
            >
                {link?.title}
                <SvgIcon name={props.active ? 'chevron-up' : 'chevron-down'} />
            </button>
        );
    }

    if (link?.url) {
        return (
            <NextLink prefetch={false} href={link.url} passHref>
                <a className={clsx(styles.navigationItem, {
                    [styles.active]: props.active
                })}
                    children={link.title}
                    title={link.title}
                    target={link.target}
                />
            </NextLink>
        );
    }

    return <></>;
});
