import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { AnimationDirectionType, animationVariations } from './animations';
import { MenuPage } from '../MenuPage';
import { CmsModel } from 'api-types';
import { useRouter } from 'next/router';
import { lookupNodeById } from '../../utils/lookupNodeById';
import { lookupNodeByUrl } from '../../utils/lookupNodeByUrl';
import { lookupParentNode } from '../../utils/lookupParentNode';
import { useTranslations } from '~/services/cms';

const StyledWrapper = styled.div({
    overflow: 'auto',
    flex: 1,
});

type MobileMegaMenuProps = {
    menu: CmsModel.NavigationNode[];
    activeNodeId?: string;
    activeNodeByUrl?: boolean;
};

type MenuState = {
    node?: CmsModel.NavigationNode;
    parentNode?: CmsModel.NavigationNode;
};

export const MobileMegaMenu = ({ menu, activeNodeId, activeNodeByUrl }: MobileMegaMenuProps) => {
    const { asPath } = useRouter();
    const translate = useTranslations();

    const [animationDirection, setAnimationDirection] = useState<AnimationDirectionType>(
        'forwards'
    );

    const [activeMenuState, setActiveMenuState] = useState<MenuState>(() => {
        let node;
        if (activeNodeId) {
            node = lookupNodeById(activeNodeId, menu);
        } else if (activeNodeByUrl) {
            node = lookupNodeByUrl(asPath, menu);
        }
        return node ? { node, parentNode: lookupParentNode(node, menu) } : {};
    });

    const onGoBackHandler = () => {
        onSelectNodeHandler(activeMenuState.parentNode);
        setAnimationDirection('backwards');
    };

    const onSelectNodeHandler = (node: CmsModel.NavigationNode | undefined) => {
        if (node) {
            const parentNode = lookupParentNode(node, menu);
            setActiveMenuState({ node, parentNode });
            setAnimationDirection('forwards');
        } else {
            // Go back to main menu
            setActiveMenuState({});
            setAnimationDirection('backwards');
        }
    };

    const { node, parentNode } = activeMenuState || {};
    const mainMenuName = translate('navigation.mobile.mainMenu.name');

    return (
        <StyledWrapper>
            <AnimatePresence mode="wait" initial={false} custom={animationDirection}>
                <motion.div
                    key={node?.link.url || 'main'}
                    variants={animationVariations}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    custom={animationDirection}
                    transition={{
                        type: 'tween',
                        duration: 0.15,
                        ease: [0.35, 0, 0.15, 1],
                    }}
                >
                    {!node && (
                        <MenuPage
                            nodes={menu}
                            headlineText={mainMenuName}
                            onSelectNode={onSelectNodeHandler}
                        />
                    )}

                    {node && (
                        <MenuPage
                            nodes={node.children || []}
                            headlineText={node.link?.title}
                            headlineLinkUrl={node.link?.url}
                            backText={parentNode?.link?.title || mainMenuName}
                            onGoBack={onGoBackHandler}
                            onSelectNode={onSelectNodeHandler}
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </StyledWrapper>
    );
};
