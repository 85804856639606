import React, { useCallback, useEffect } from 'react';
import { Dropdown, Text } from 'ui';

import { useMarkets } from '~/services/globalization';
import { useRouter } from 'next/router';

import styles from './MarketSelector.module.css';
import { isJPG, isPorsche } from 'utils';
import { useTranslations } from '~/services/cms';
import { useAuthentication } from '~/shared/hooks';

export const MarketSelector = () => {
    const { currentMarket, getStoreMarket } = useMarkets();
    const { session, isAuthenticated } = useAuthentication();
    const translate = useTranslations();
    const router = useRouter();
    const isHovw = !isJPG() && !isPorsche();

    const onMarketChange = (market: string) => {
        switchToLocale(market);
    }

    const switchToLocale = useCallback(
        (locale: string) => {
            return router.push('/', '/', { locale });
        },
        [router]
    );

    useEffect(() => {
        if (session?.storeKey && !isHovw && isAuthenticated) {
            const userMarket = getStoreMarket(session.storeKey);
            if (userMarket?.countryCode) {
                const userMarketDefaultLanguage = userMarket.languages.find((language) => language.isDefault)?.languageCode || 'da';
                if (userMarketDefaultLanguage?.toLocaleLowerCase() !== router.locale?.toLocaleLowerCase()) {
                    switchToLocale(userMarketDefaultLanguage?.toLocaleLowerCase() || 'da');
                }
            }
        }
    }, [session?.storeKey, isAuthenticated]);

    const languageOptions = currentMarket?.languages.map(language => {
        return {
            label: `${language?.languageName}` || '',
            value: language?.languageCode || ''
        };
    }) || [];

    if (languageOptions.length === 0 || isHovw) return null;

    return (
        <div className={styles.marketSelector}>
            <Text variant='label' className={styles.marketSelectorLabel}><b>{translate('navigation.languageSelector')}</b></Text>
            <Dropdown
                items={languageOptions}
                name={translate('navigation.languageSelector')}
                onChange={(value) =>
                    value && onMarketChange(value as string)
                }
                value={router.locale}
            />
        </div>
    );
};
