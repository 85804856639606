import React, { memo } from 'react';
import { useFrame } from '~/services/cms';
import { Link, MaxWidth } from 'ui';

import { Usp } from './components/Usp';
import { weakKey } from '~/shared/utils/jsx';
import { withErrorBoundary } from '~/shared/utils/errorBoundary';
import styles from './N10Meta.module.css';

const N10Meta = memo(() => {
    const { data } = useFrame();
    const { metaMenu } = data?.navigation || {};
    const { uspList } = data?.usp || {};

    return (
        <div className={styles.n10Meta}>
            <MaxWidth className={styles.content}>
                <div className={styles.innerContent}>
                    {!!uspList?.length && <Usp uspItems={uspList} />}

                    {!!metaMenu?.length && (
                        <ul className={styles.list}>
                            {metaMenu.map(
                                (link) =>
                                    link.url && (
                                        <li key={weakKey({ link })}>
                                            <Link className={styles.link}
                                                href={link.url}
                                                children={link.title}
                                                {...link}
                                            />
                                        </li>
                                    )
                            )}
                        </ul>
                    )}
                </div>
            </MaxWidth>
        </div>
    );
});

export default withErrorBoundary(N10Meta);
