import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { CmsModel } from 'api-types';
import { variants, animatedOverlay } from './animations';
import { Backdrop, Menu, NavigationItem, PromotedChildren } from './components';
import { useReducedMotion } from 'framer-motion';
import { useClickAway, useDebounce, useWindowSize } from 'react-use';
import { Gutter } from 'ui';
import { NavigationLink } from '../N30MegaMenu/components/NavigationLink';
import { motion } from 'framer-motion';

import clsx from 'clsx';
import styles from './N30MegaMenu.module.css';
import { sizes } from '~/constants';
import { useScrollLock } from 'utils';

export type Props = {
    navigation: CmsModel.NavigationNode[];
};

/**
 * A basic navigation with related mega menu.
 * covers basic SEO and accessibility needs.
 * Handles active/inactive state and close on route change.
 */
export const N30MegaMenu = memo(({ navigation }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [activeNode, setActiveNode] = useState<HTMLElement | null>(null);
    const navigationRef = useRef<HTMLElement>(null);
    const { asPath } = useRouter();
    const shouldReduceMotion = useReducedMotion();
    const { lock, unlock, isLocked } = useScrollLock();
    const { width, height } = useWindowSize();
    const [activeNodeHeight, setActiveNodeHeight] = useState<number>(0);

    useEffect(() => {
        if (height > (activeNode?.offsetHeight ?? 0) + 113) {
            setActiveNodeHeight(activeNode?.offsetHeight ?? 0);
        } else {
            setActiveNodeHeight(height - 200);
        }
    }, [activeNode, height]);

    useDebounce(
        () => {
            if (height > (activeNode?.offsetHeight ?? 0) + 113) {
                setActiveNodeHeight(activeNode?.offsetHeight ?? 0);
            } else {
                setActiveNodeHeight(height - 200);
            }
        },
        50,
        [width, height]
    );

    useClickAway(navigationRef, () => {
        setActiveIndex(null);
        if (isLocked) {
            unlock();
        }
    });

    useEffect(() => {
        setActiveIndex(null);
        unlock();
    }, [asPath]);

    const measuredRef = useCallback((node: HTMLDivElement) => {
        setActiveNode(node);
    }, []);

    const keyMap = useCallback(
        (key: string) =>
        (({
            Escape: () => setActiveIndex(null),
        } as Record<string, () => void>)[key]?.()),
        []
    );

    const clickedNagivationItem = useCallback((isActive: boolean, index: number) => {
        if (isActive) {
            setActiveIndex(null);
            unlock();
        } else {
            setActiveIndex(index);
            lock();
        }
    }, [lock, activeIndex]);

    const styledContentMaxWidth = {
        "--content-max-width": `${sizes.contentMaxWidth}px`,
    } as React.CSSProperties;

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <nav onKeyDown={({ key }) => keyMap(key)} ref={navigationRef}>
            {!shouldReduceMotion && <>
                <motion.div
                    className={clsx(styles.dropDownOverlay)}
                    variants={animatedOverlay}
                    style={{ display: activeIndex && activeNodeHeight > 0 ? 'block' : 'none' }}
                    animate={activeIndex && activeNodeHeight > 0 ? 'active' : 'inactive'}
                    initial="inactive"
                />
                <Backdrop activeNodeHeight={activeNodeHeight} />

            </>}

            <ul className={clsx(styles.list)}>
                {navigation?.map(({ children, promotedChildren, link }, index) => {
                    const isActive = index === activeIndex;
                    return (
                        <li key={index}>
                            <NavigationItem
                                active={isActive || asPath === link?.url}
                                onClick={() => clickedNagivationItem(isActive, index)}
                                subNodes={children}
                                link={link}
                            />
                            {!!children?.length && (
                                <motion.div
                                    className={clsx(styles.dropDown)}
                                    variants={variants(shouldReduceMotion)}
                                    style={{ display: isActive ? 'block' : undefined, maxHeight: activeNodeHeight }}
                                    animate={isActive ? 'active' : 'inactive'}
                                    initial="inactive"
                                >
                                    <Gutter>
                                        <div ref={isActive ? measuredRef : undefined} className={clsx(styles.dropDownMaxWidth)} style={styledContentMaxWidth}>
                                            <NavigationLink link={link} variant="header" />
                                            <div className={clsx(styles.dropDownContent)}

                                            >
                                                <Menu nodes={children} />
                                                {!!promotedChildren?.length && (
                                                    <PromotedChildren nodes={promotedChildren} />
                                                )}
                                            </div>
                                        </div>
                                    </Gutter>
                                </motion.div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
});
